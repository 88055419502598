import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import SanityLink from '../global/SanityLink'
import useSiteSettings from '../../hooks/useSiteSettings'
import dfhPatternAllColors from '../../img/hero/dfh-pattern-allcolors.svg'
import dfhPatternGray from '../../img/hero/dfh-pattern-gray.svg'
import { Maybe, SanityPageHero } from '../../graphql/gatsby'

// exclude additional props not required by component
export type SanityHeroTagSelection = Pick<NonNullable<SanityPageHero['heroTag']>, 'tag' | 'cta'>

export type SanityPageHeroSelection = Omit<SanityPageHero, 'heroTag'> & {
  heroTag?: Maybe<SanityHeroTagSelection>
}

export interface PageHeroProps extends SanityPageHeroSelection {
  className?: string
}

const PageHero: React.FC<PageHeroProps> = ({
  className,
  title,
  isTitleTopLevelHeader,
  heroImage,
  heroTag,
}) => {
  const { defaultHeroTag } = useSiteSettings()

  let imageSrc
  switch (heroImage) {
    case 'dfh-pattern-allcolors':
      imageSrc = dfhPatternAllColors
      break
    case 'dfh-pattern-gray':
      imageSrc = dfhPatternGray
      break
    default:
      imageSrc = dfhPatternGray
      break
  }
  const heroImageElement = <img src={imageSrc} className="hero-bg" alt="" />

  let titleElement

  if (isTitleTopLevelHeader) {
    titleElement = (
      <h1 id="mainContent" className="title">
        {title}
      </h1>
    )
  } else {
    titleElement = <div className="title">{title}</div>
  }

  const heroTagToUse = heroTag || defaultHeroTag

  return (
    <div className={className}>
      {heroImageElement}
      <div className="content">
        {titleElement}
        <div className="tag-and-cta">
          <div className="tag" id="aria-hero-tag">
            {heroTagToUse?.tag}
          </div>
          {heroTagToUse?.cta && (
            <SanityLink to={heroTagToUse.cta.link || undefined} aria-describedby="aria-hero-tag">
              {heroTagToUse.cta.title}
            </SanityLink>
          )}
        </div>
      </div>
    </div>
  )
}

export const defaultHeroTagFragment = graphql`
  fragment defaultHeroTag on SanitySiteSettings {
    defaultHeroTag {
      tag
      cta {
        title
        link {
          ...LinkFragment
        }
      }
    }
  }
`

const StyledPageHero = styled(PageHero)`
  position: relative;
  overflow: hidden;
  min-width: 320px;
  box-sizing: border-box;
  .hero-bg {
    display: block;
    position: absolute;
    top: -48px;
    left: 50%;
    transform: translateX(-50%) translateX(55px);
    width: 892px;
    pointer-events: none;
    max-width: unset;
  }
  .content {
    position: relative;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.grayscale.white};
    margin: 0 auto;
    padding: 0 16px 32px;
    box-sizing: border-box;
    .title {
      font-family: ${({ theme }) => theme.fontFamilies.gotham};
      font-weight: bold;
      font-size: 1.3125rem;
      color: ${({ theme }) => theme.colors.grayscale.white};
      margin-top: 24px;
      margin-bottom: 0;
      &.lowercase {
        text-transform: lowercase;
      }
      &.uppercase {
        text-transform: uppercase;
      }
    }
  }
  .tag-and-cta {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    .tag {
      width: 85%;
      max-width: 290px;
      margin: 0 auto;
    }
    &,
    p {
      font-weight: 500;
      font-size: 0.6875rem;
      letter-spacing: 0.055em;
      line-height: 1.25;
      color: ${({ theme }) => theme.colors.grayscale.white};
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (min-width: 576px) {
    .hero-bg {
      width: 933px;
    }
    .content {
      padding-bottom: 2.5rem;
      .tag-and-cta {
        margin-top: 8.5rem;
      }
    }
  }
  @media (min-width: 768px) {
    .hero-bg {
      width: 1100px;
      top: -4.5rem;
      transform: translateX(-50%) translateX(55px);
    }
    .content {
      .title {
        font-size: 1.5rem;
      }
      .tag-and-cta {
        flex-direction: column-reverse;
        margin-top: 10rem;
        .tag {
          max-width: 420px;
          &,
          p {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
  @media (min-width: 992px) {
    .hero-bg {
      width: 1337px;
      top: -2.5rem;
      transform: translateX(-50%);
    }
    .content {
      text-align: left;
      .title {
        font-size: 1.875rem;
        margin-top: 3rem;
        margin-left: 17.46%;
      }
      .tag-and-cta {
        flex-direction: row;
        margin-top: 1rem;
        .tag {
          margin-top: 0;
          margin-right: 6px;
          margin-left: auto;
          max-width: 320px;
          &,
          p {
            font-size: 0.75rem;
          }
        }
      }
    }
  }
`

export default StyledPageHero
