import { useMutation } from '@apollo/client'
import { Alert } from '@designsforhealth/dfh-react-components'
import { CircularProgress } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { ProtocolResourceQuery } from '../../../graphql/api'
import {
  AddSimpleProductsToCartDocument,
  AddSimpleProductsToCartMutation,
} from '../../../graphql/magento'
import { TokenContext } from '../../../layouts/context'
import { publishAddToCartEventForItems } from '../../../lib/analytics/cart'
import { useCartContext } from '../../../lib/cart'
import { useAddToCartToast } from '../../../lib/cart/hooks/useAddToCartToast'
import { useDispensaryCartStatus } from '../../../lib/dispensaries/events/cart'
import { logAndCaptureException } from '../../../utils/errorTools'
import { tapSuccessfulQueryResponse } from '../../../utils/graphqlTools'
import { CartItem } from '../../shop/account/orders/order/OrderPageContent'
import * as Styled from './Protocol/styled'

import favIcon from '../../../img/favicon.png'

interface ProtocolAddToCartProps {
  products: NonNullable<ProtocolResourceQuery['protocolResource']>['products']
  hasAdjunctProducts: boolean
}

const ProtocolAddToCart: React.FC<ProtocolAddToCartProps> = ({ products, hasAdjunctProducts }) => {
  const { loading: dispensaryCartLoading } = useDispensaryCartStatus()
  const token = useContext(TokenContext)
  const { cart } = useCartContext()
  const [outOfStockError, setOutOfStockError] = useState<string | undefined>(undefined)
  const { showSuccessToast } = useAddToCartToast()

  const { cartItems, skus } = useMemo(() => {
    const protocolProducts = products ?? []
    return protocolProducts?.reduce(
      (acc: { cartItems: CartItem[]; skus: string[] }, item) => {
        if (item?.product?.sku && item?.quantity != null) {
          acc.cartItems.push({
            data: {
              sku: item.product.sku,
              quantity: item.quantity,
              dfh_order_type: 1,
              dfh_autoship_interval: 1,
            },
          })
          if (!acc.skus.includes(item.product.sku)) {
            acc.skus.push(item.product.sku)
          }
        }
        return acc
      },
      { cartItems: [], skus: [] }
    )
  }, [products])

  const onCompleted = useCallback(
    (mutationData: AddSimpleProductsToCartMutation) => {
      if (mutationData?.addSimpleProductsToCart) {
        showSuccessToast({ skus: skus })
      }
    },
    [showSuccessToast, skus]
  )

  const [addSimpleProductsToCart, { loading: addToCartLoading, error }] = useMutation(
    AddSimpleProductsToCartDocument,
    {
      context: { token },
      onCompleted,
    }
  )

  useEffect(() => {
    if (error) {
      logAndCaptureException(error)
    }
    setOutOfStockError(
      error?.graphQLErrors.find((graphQLError) =>
        /Product that you are trying to add is not available/.test(graphQLError.message)
      )?.message
    )
  }, [error])

  const handleReorderAll = useCallback(() => {
    const cartId = cart?.id
    if (!cartId) {
      return
    }
    if (cartItems.length > 0) {
      addSimpleProductsToCart({
        variables: {
          cartId,
          cartItems,
        },
      }).then(
        tapSuccessfulQueryResponse(
          publishAddToCartEventForItems({
            items: cartItems.map(({ data: { sku, quantity } }) => ({ sku, quantity })),
          })
        )
      )
    }
  }, [addSimpleProductsToCart, cart?.id, cartItems])

  return (
    <Styled.ItemEntryContainer>
      <Styled.GapContainer>
        <Styled.ItemDetails>
          <Styled.PlaceholderIconContainer>
            <Styled.PlaceholderIcon alt="placeholder icon" src={favIcon} />
          </Styled.PlaceholderIconContainer>
          <div>
            <Styled.AllInProtocolText>
              All {hasAdjunctProducts ? 'Primary Support ' : ''}Products In Protocol
            </Styled.AllInProtocolText>
          </div>
        </Styled.ItemDetails>
        <Styled.ButtonContainer>
          <Styled.AddToCartButton
            onClick={handleReorderAll}
            disabled={addToCartLoading || dispensaryCartLoading}
          >
            {addToCartLoading ? <CircularProgress size={16} color="inherit" /> : 'Add All To Cart'}
          </Styled.AddToCartButton>
        </Styled.ButtonContainer>
      </Styled.GapContainer>
      {error && (
        <Alert type="error">{outOfStockError || 'Could not add product to shopping cart.'}</Alert>
      )}
    </Styled.ItemEntryContainer>
  )
}

export default ProtocolAddToCart
