import { useMutation } from '@apollo/client'
import { Alert } from '@designsforhealth/dfh-react-components'
import { CircularProgress } from '@material-ui/core'
import { GraphQLError } from 'graphql'
import { compose, test } from 'ramda'
import React, { useCallback, useContext, useMemo } from 'react'

import {
  AddSimpleProductsToCartDocument,
  AddSimpleProductsToCartMutation,
} from '../../../graphql/magento'
import { IndexedProduct } from '../../../graphql/search'
import useLogAndCaptureError from '../../../hooks/useLogAndCaptureError'
import { TokenContext } from '../../../layouts/context'
import { publishAddToCartEventForItems } from '../../../lib/analytics/cart'
import { useCartContext } from '../../../lib/cart'
import { useAddToCartToast } from '../../../lib/cart/hooks/useAddToCartToast'
import { logAndCaptureException } from '../../../utils/errorTools'
import { tapSuccessfulQueryResponse } from '../../../utils/graphqlTools'
import Link from '../../global/Link'
import * as Styled from './Protocol/styled'

import productPlaceholderImage from '../../../img/product-image-placeholder.png'

export type Product = Pick<
  IndexedProduct,
  'id' | 'name' | 'urlKey' | 'shortDescription' | 'sanityImageJson' | 'sku' | 'sanityImageUrl'
>
export interface ProductEntryProps {
  item: Product
  itemSku: string
  itemQuantity: number
}

const isProductNotAvailableError = compose(
  test(/Product that you are trying to add is not available/),
  (obj: GraphQLError) => obj.message
)
export const ProductEntry: React.FC<ProductEntryProps> = ({ item, itemSku, itemQuantity }) => {
  const token = useContext(TokenContext)
  const { cart } = useCartContext()
  const { showSuccessToast } = useAddToCartToast()

  const onCompleted = useCallback(
    (data: AddSimpleProductsToCartMutation) => {
      if (data?.addSimpleProductsToCart) {
        showSuccessToast({ skus: [itemSku] })
      }
    },
    [itemSku, showSuccessToast]
  )

  const [addSimpleProductsToCart, { loading: addToCartLoading, error }] = useMutation(
    AddSimpleProductsToCartDocument,
    {
      context: { token },
      onCompleted,
    }
  )

  useLogAndCaptureError(error)

  const errorMessage = useMemo(() => {
    if (!error) return undefined

    const catchableError = error.graphQLErrors.find(isProductNotAvailableError)
    if (!catchableError) {
      logAndCaptureException(error)
      return undefined
    }
    return catchableError.message
  }, [error])

  const handleReorder = useMemo(() => {
    const cartId = cart?.id
    const sku = itemSku
    if (!cartId || !sku) {
      return undefined
    }

    return () =>
      addSimpleProductsToCart({
        variables: {
          cartId,
          cartItems: [
            {
              data: {
                sku,
                quantity: itemQuantity,
                dfh_order_type: 1,
                dfh_autoship_interval: 1,
              },
            },
          ],
        },
      }).then(
        tapSuccessfulQueryResponse(
          publishAddToCartEventForItems({
            items: [
              {
                quantity: itemQuantity,
                sku,
              },
            ],
          })
        )
      )
  }, [addSimpleProductsToCart, cart?.id, itemSku, itemQuantity])
  return (
    <Styled.ItemEntryContainer>
      <Styled.GapContainer>
        <Styled.ItemDetails>
          <Link to={`/products/${item.urlKey}`}>
            <Styled.ItemImage
              alt={item.name}
              src={item.sanityImageUrl || productPlaceholderImage}
            />
          </Link>
          <div>
            <Styled.ItemProductLink urlKey={item.urlKey}>
              <Styled.ItemName>{item.name}</Styled.ItemName>
            </Styled.ItemProductLink>
            <Styled.ItemAttribute>SKU: {itemSku}</Styled.ItemAttribute>
            <Styled.ItemAttribute>Quantity: {itemQuantity}</Styled.ItemAttribute>
            <Styled.ItemAttributeText>
              {(item.shortDescription || {}).text || ''}
            </Styled.ItemAttributeText>
          </div>
        </Styled.ItemDetails>
        <Styled.ButtonContainer>
          <Styled.AddToCartButton onClick={handleReorder} disabled={addToCartLoading}>
            {addToCartLoading ? <CircularProgress size={16} color="inherit" /> : 'Add To Cart'}
          </Styled.AddToCartButton>
        </Styled.ButtonContainer>
      </Styled.GapContainer>
      {error && (
        <Alert type="error">{errorMessage || 'Could not add product to shopping cart.'}</Alert>
      )}
    </Styled.ItemEntryContainer>
  )
}
