import { PrimaryButton, SecondaryButton } from '@designsforhealth/dfh-react-components'
import { Grid } from '@material-ui/core'
import BlockContentImport from '@sanity/block-content-to-react'
import styled, { css } from 'styled-components'

import { flexboxGapStyles } from '../../../../lib/styles/flexbox-gap'
import { headingFontSize } from '../../../../theme/helpers'
import OptionalProductLink from '../../../OptionalProductLink'
import DefaultPageHero from '../../../sanity-page/PageHero'

import MagnifyingGlassSvg from '../../../../img/magnifying-glass.inline.svg'

export const ViewProtocolButton = styled(PrimaryButton)`
  font-size: 15px;
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  display: block;
  max-width: 400px;
  width: 100%;
`

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
`

export const BlockContent = styled(BlockContentImport)`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  word-wrap: break-word;

  &,
  & p {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 1rem;
    line-height: 1.9;
  }
`

export const MagnifyingGlass = styled(MagnifyingGlassSvg)`
  color: ${({ theme }) => theme.colors.brand.orange};
  display: inline-block;
  height: 2em;
  vertical-align: -0.7em;
`

export const TopNav = styled.nav`
  padding: 0 20px;
  margin: 24px 0;
  a {
    color: ${({ theme }) => theme.colors.component.link};
  }
  @media (min-width: 600px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`

export const ItemsContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  gap: 16px;
  margin-bottom: 32px;
`

export const GapContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // use negative margins to workaround lack of flexbox gap support in older browsers
  ${flexboxGapStyles('10px')};
`

export const ItemDetails = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 320px;
  flex: 1 0 320px;
  align-items: center;
`

export const ItemProductLink = styled(OptionalProductLink)`
  color: inherit;
  text-decoration: none;
  transition: color 0.15s;
  &:hover {
    color: ${({ theme }) => theme.colors.component.link};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 1;
  ${SecondaryButton} {
    margin: 0.5rem;
  }
`

export const ItemName = styled.h4`
  max-width: 320px;
  font-family: ${({ theme }) => theme.fontFamilies.roboto};
  line-height: 1.43;
`

export const ItemImage = styled.img`
  align-self: flex-start;
  border-radius: 4px;
  height: 115px;
  width: 115px;
  min-width: 115px;
  margin-right: 16px;
`

export const AddToCartButton = styled(SecondaryButton)`
  flex: 1;
  min-width: 172px;
`

export const ItemEntryContainer = styled.div`
  overflow: hidden;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.coolGray.cool250};
`

const itemAttributeStyles = css`
  color: ${({ theme }) => theme.colors.grayscale.black};
  font-size: 12px;
  line-height: 1.67;
  display: block;
`

export const ItemAttribute = styled.div`
  ${itemAttributeStyles}
`

export const ItemAttributeText = styled.div`
  ${itemAttributeStyles};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  @media (min-width: 576px) {
    max-width: 200px;
  }
  @media (min-width: 768px) {
    max-width: 275px;
  }
  @media (min-width: 992px) {
    max-width: 375px;
  }
`

export const DispensaryNotFoundMessage = styled.p`
  font-family: ${({ theme }) => theme.fontFamilies.gotham};
  font-size: 15px;
  margin-bottom: 0;
`

export const PhoneNumber = styled.a`
  color: ${({ theme }) => theme.colors.brand.red};
`

export const PaddedContainer = styled.div`
  padding: 0 32px;
  margin-bottom: 64px;
  @media (min-width: 600px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`

export const MaxWidthGrid = styled(Grid)`
  && {
    max-width: 800px;
    margin: 0 auto;
  }
`

export const SectionTitle = styled.h3`
  font-size: ${({ theme }) => headingFontSize({ size: 2, theme })};
  margin-bottom: 0;
`

export const PageHero = styled(DefaultPageHero)`
  background-color: ${({ theme }) => theme.colors.component.backgroundDarkBlue};
  @media (min-width: 992px) {
    && {
      .hero-bg {
        top: -2.5rem;
      }
      .content {
        .tag-and-cta {
          margin-top: 1rem;
        }
      }
    }
  }
`

export const PlaceholderIconContainer = styled.div`
  width: 115px;
  min-width: 115px;
  margin-right: 16px;
`

export const PlaceholderIcon = styled.img`
  height: 60px;
  width: 60px;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const AllInProtocolText = styled(ItemName)`
  margin-bottom: 0;
`
